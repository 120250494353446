<template>
  <div class="contentbox">
    <div class="typebox">
      <div
        class="typeitem point"
        :class="{ actype: actypeindex === item.id }"
        @click="typechange(item.id)"
        v-for="item in typedata"
        :key="item.id"
      >
        {{ item.title }}
      </div>
    </div>
    <!-- 取回 -->
    <div v-if="actypeindex === 3" class="pullbox">
      <div class="selectbox">
        <div class="select-box point" @click="isShowFloat = !isShowFloat">
          <div class="input-value flex-s">
            {{ parseSelectList.find((item) => item.value == value)?.label }}
          </div>
          <div class="row">
            <img
              src="../../assets/images/NewUser/pc-newuser-pullicon.png"
              alt=""
            />
          </div>
          <div v-show="isShowFloat" class="float-box">
            <div
              @click="changeSelect(item)"
              class="float-box-item flex-s"
              v-for="(item, index) in parseSelectList"
              :key="index"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 钱包 -->
    <div v-if="actypeindex === 2" class="pullbox">
      <div class="selectbox">
        <div class="select-box point" @click="pisShowFloat = !pisShowFloat">
          <div class="input-value flex-s">
            {{ SelectList.find((item) => item.pvalue == pvalue)?.label }}
          </div>
          <div class="row">
            <img
              src="../../assets/images/NewUser/pc-newuser-pullicon.png"
              alt=""
            />
          </div>
          <div v-show="pisShowFloat" class="float-box">
            <div
              @click="pricechangeSelect(item)"
              class="float-box-item flex-s"
              v-for="(item, index) in SelectList"
              :key="index"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 积分 -->
    <div v-if="actypeindex === 6" class="pullbox">
      <div class="selectbox">
        <div class="select-box point" @click="pisShowFloat = !pisShowFloat">
          <div class="input-value flex-s">
            {{
              pointtypeList.find((item) => item.pointalue == pointalue)?.label
            }}
          </div>
          <div class="row">
            <img
              src="../../assets/images/NewUser/pc-newuser-pullicon.png"
              alt=""
            />
          </div>
          <div v-show="pisShowFloat" class="float-box">
            <div
              @click="pointchangeSelect(item)"
              class="float-box-item flex-s"
              v-for="(item, index) in pointtypeList"
              :key="index"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="depotcontent">
      <!-- 背包 -->
      <div class="contentbox1" v-if="actypeindex === 1">
        <div class="dcenter">
          <div class="clickbox">
            <div class="lbox">
              <div class="clickall" @click="SelectAll">
                <img
                  v-show="!IS_Select_All"
                  class="point"
                  src="../../assets/images/NewUser/pc-newuser-depotclick.png"
                  alt=""
                />
                <img
                  v-show="IS_Select_All"
                  class="point"
                  src="../../assets/images/NewUser/pc-newuser-depotacclick.png"
                  alt=""
                />
                <p>全选</p>
              </div>
              <div class="checktext">
                <p>
                  已选
                  <span style="color: yellow; margin: 0 0.05rem">{{
                    chooseIdList.length
                  }}</span>
                  件
                </p>
              </div>
            </div>
            <div class="rbox">
              <div class="btn1 point" @click="WholeExtract">提货</div>
              <div class="btn2 point" @click="$router.push('/fusion')">
                熔炼
              </div>
            </div>
          </div>
          <!-- 背包列表 -->
          <div style="font-size: 0.16rem; display: flex; align-items: center">
            <img
              style="width: 0.16rem; margin-right: 0.1rem"
              src="../../assets/images//NewUser//pc-newuser-gantan.png"
              alt=""
            />
            若频繁取消报价，用户将受到一段时间内禁止提货的处罚
          </div>
          <div class="depotlist">
            <div
              class="depotdata point"
              v-for="(item, index) in WarehouseLists"
              :key="index"
              :class="{
                'item-ac': acShop.indexOf(item) != -1,
                'Warehouse-List-item-active': chooseIdList.includes(item.id),
              }"
              ref="Select"
              @click="itemOnClick(item, index)"
            >
              <div class="durabox">
                <span v-show="item.dura_alias != '无'">{{
                  item.dura_alias
                }}</span>
              </div>
              <div class="imgbox">
                <img v-lazy="item.cover" alt="" />
              </div>
              <div
                class="itemname"
                @mouseenter="showtext(item.name, item.id)"
                @mouseleave="closetext"
              >
                {{ item.name }}
              </div>
              <div class="showtextbox" v-show="showtextstate == item.id">
                {{ showtextvalue }}
              </div>
              <div class="itembean">
                <!-- <Cint :price="item.bean" :size="0.14" /> -->
              </div>
            </div>
          </div>
          <div class="pagination">
            <Pagination
              v-show="total > 0"
              :total="total"
              :page.sync="page_Num"
              :limit.sync="per_page"
              @pagination="handleCurrentChange"
            />
          </div>
        </div>
      </div>
      <!-- 钱包记录 -->
      <div class="getrecordbox" v-if="actypeindex === 2">
        <NewTable
          :tableRecordList="tableRecordList4"
          :tableData="tableData"
          @pageChange="priceChange"
        />
      </div>

      <!-- 提货记录 -->
      <div class="getrecordbox" v-if="actypeindex === 3">
        <NewTable
          :tableRecordList="tableRecordList2"
          :tableData="acceptData"
          renderSearch
          @pageChange="PickChange"
        >
          <template slot-scope="item">
            <div v-if="item.item == 'name'">
              {{ item.data.name }}
              {{ item.data.dura_alias == "无" ? "" : item.data.dura_alias }}
            </div>
          </template>
        </NewTable>
      </div>
      <!-- 弹药记录 -->
      <div class="getrecordbox" v-if="actypeindex === 4">
        <NewTable
          :tableRecordList="tableRecordList8"
          :tableData="newlistData"
          renderSearch
          @pageChange="danyaochange"
        >
          <template slot-scope="item">
            <div
              v-if="item.item == 'box_record_names'"
              class="point"
              @mouseenter="showgetname(item.data.id)"
              @mouseleave="closeindex"
            >
              <div class="listitem">
                {{ item.data?.box_record_names[0] }}
              </div>
              <div
                class="showlist"
                @mouseenter="showgetname(item.data.id)"
                @mouseleave="closeindex"
                v-show="item.data.id == acgetnameindex"
              >
                <div
                  class="putlist"
                  v-for="(v, i) in item.data.box_record_names"
                  :key="i"
                >
                  {{ v }}
                </div>
              </div>
            </div>
            <div
              v-if="item.item == 'names'"
              class="point"
              @mouseenter="showlistname(item.data.id)"
              @mouseleave="closeindex"
            >
              <div class="listitem">
                {{ item.data?.names[0] }}
              </div>
              <div
                class="showlist"
                @mouseenter="showlistname(item.data.id)"
                @mouseleave="closeindex"
                v-show="item.data.id == aclistindex"
              >
                <div class="putlist" v-for="(v, i) in item.data.names" :key="i">
                  {{ v }}
                </div>
              </div>
            </div>
            <div v-if="item.item == 'type'">弹药开箱</div>
            <div
              v-if="item.item == 'award_name'"
              class="point"
              @mouseenter="showzhen(item.data.id)"
              @mouseleave="closeindex"
            >
              <div class="listitem">
                {{ item.data.award_name }}
              </div>
              <div
                class="showlist2"
                @mouseenter="showzhen(item.data.id)"
                @mouseleave="closeindex"
                v-show="item.data.id == aczhensongindex"
              >
                <div class="putlist">
                  {{ item.data.award_name }}
                </div>
              </div>
            </div>
          </template>
        </NewTable>
      </div>
      <!-- 熔炼记录 -->
      <div class="getrecordbox" v-if="actypeindex === 5">
        <NewTable
          :tableRecordList="tableRecordList9"
          :tableData="newlistData"
          renderSearch
          @pageChange="ronglianchange"
        >
          <template slot-scope="item">
            <div
              v-if="item.item == 'names'"
              class="point"
              @mouseenter="showlistname(item.data.id)"
              @mouseleave="closeindex"
            >
              <div class="listitem">
                {{ item.data?.names[0] }}
              </div>
              <div
                class="showlist"
                @mouseenter="showlistname(item.data.id)"
                @mouseleave="closeindex"
                v-show="item.data.id == aclistindex"
              >
                <div class="putlist" v-for="(v, i) in item.data.names" :key="i">
                  {{ v }}
                </div>
              </div>
            </div>
            <div v-if="item.item == 'type'">熔炼合成</div>
            <div
              v-if="item.item == 'award_name'"
              @mouseenter="showzhen(item.data.id)"
              @mouseleave="closeindex"
            >
              <div class="listitem">
                {{ item.data.award_name }}
              </div>
            </div>
          </template>
        </NewTable>
      </div>
      <!-- 积分记录 -->
      <div class="getrecordbox" v-if="actypeindex === 6">
        <NewTable
          :tableRecordList="tableRecordList10"
          :tableData="newlistData"
          @pageChange="pointlistchange"
        >
        </NewTable>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Storage,
  Extract,
  PersonalInfo,
  Recharge_record,
  getsynthesisData,
  getammunitionData,
  pointlistApi,
} from "@/network/api.js";
import { mapMutations } from "vuex";
import NewTable from "./components/NewTable.vue";
import {
  tableData,
  tableRecordList3,
  tableRecordList2,
  acceptData,
  newlistData,
  tableRecordList4,
  tableRecordList8,
  tableRecordList9,
  tableRecordList10
} from "./index.js";
export default {
  components: {
    NewTable,
  },
  data() {
    return {
      tableData,
      tableRecordList3,
      tableRecordList2,
      tableRecordList4,
      tableRecordList8,
      tableRecordList9,
      tableRecordList10,
      acceptData,
      newlistData,
      typedata: [
        {
          id: 1,
          title: "我的背包",
        },
        {
          id: 2,
          title: "钱包记录",
        },
        {
          id: 6,
          title: "积分记录",
        },
        {
          id: 4,
          title: "弹药开箱",
        },
        {
          id: 5,
          title: "熔炼记录",
        },
        {
          id: 3,
          title: "提货记录",
        },
        
      ],
      actypeindex: 1,
      WarehouseLists: [],
      AllWarehouseObj: {},
      chooseIdList: [],
      total: 0,
      acShop: [], //选中的数组
      per_page: 10,
      page_Num: 1,
      bean: 0,
      num: 0,
      sort: 0,

      getrecorddata: [], // 取回记录数据
      isShowFloat: false, //下拉状态
      pisShowFloat: false, //钱包下拉
      selectList: [
        {
          value: "1",
          label: "正在发货",
        },
        {
          value: "2",
          label: "提货完成",
        },
      ],
      value: "1",
      //钱包记录下拉
      priceselectList: [
        {
          label: "全部",
          pvalue: "0",
        },
        {
          label: "开箱",
          pvalue: "1",
        },
        {
          label: "装备回收",
          pvalue: "4",
        },
        {
          label: "充值",
          pvalue: "5",
        },
        {
          label: "首充奖励",
          pvalue: "6",
        },
        {
          label: "转盘奖励",
          pvalue: "33",
        },
      ],
      pvalue: "0",
      // 积分记录下拉
      pointtypeList: [
        {
          label: "全部",
          pointalue: "0",
        },
        {
          label: "开箱",
          pointalue: "1",
        },
        {
          label: "充值",
          pointalue: "2",
        },
      ],
      pointalue: "0",
      pointtype: null,
      page_num: 1,
      curtype: null,
      PickCode: 9, //提货状态
      aclistindex: null, //选中下标
      user_id: "",
      acgetnameindex: null,
      aczhensongindex: null,
      showtextstate: null,
      showtextvalue: "",
    };
  },
  created() {
    this.GetStorage(1);
  },

  computed: {
    SelectList() {
      const array = this.priceselectList.map((item) => {
        return {
          label: item?.label || item,
          pvalue: item?.pvalue || item,
        };
      });
      return array;
    },
    parseSelectList() {
      const array = this.selectList.map((item) => {
        return {
          label: item?.label || item,
          value: item?.value || item,
        };
      });
      return array;
    },
    allPrice() {
      let price = 0;
      this.chooseIdList.forEach((item) => {
        price = price * 1 + this.AllWarehouseObj[item + ""].bean * 1;
      });
      return price;
    },
    WarehouseIdLists() {
      const arr = this.WarehouseLists.map((item) => {
        return item.id;
      });
      return arr;
    },
    IS_Select_All() {
      let flag = true;
      this.WarehouseIdLists.forEach((item) => {
        if (!this.chooseIdList.includes(item)) {
          flag = false;
        }
      });
      return flag;
    },
  },

  methods: {
    closetext() {
      this.showtextstate = null;
      this.showtextvalue = null;
    },
    showtext(v, i) {
      this.showtextstate = i;
      this.showtextvalue = v;
    },
    closeindex() {
      this.aclistindex = "";
      this.acgetnameindex = "";
      this.aczhensongindex = "";
    },
    showlistname(v) {
      this.aclistindex = v;
    },
    showgetname(v) {
      this.acgetnameindex = v;
    },
    showzhen(v) {
      this.aczhensongindex = v;
    },
    async getpointlistdata() {
      let params = {
        type: this.pointtype,
        page: this.page_num,
      };
      const res = await pointlistApi(params);
      let data = res.data.data.data;
      this.newlistData = {
        total: res.data.data.total,
        pageSize: Number(res.data.data.per_page),
        current_page: res.data.data.current_page,
        list: data.map((item) => {
          return {
            ...item,
          };
        }),
      };
    },
    // 积分分页
    pointlistchange(v) {
      this.page_num = v.page;
      this.getpointlistdata();
    },
    //熔炼分页
    ronglianchange(v) {
      this.page_num = v.page;
      this.getronliandata();
    },
    // 弹药分页
    danyaochange(v) {
      this.page_num = v.page;
      this.getdanyaodata();
    },

    async getronliandata() {
      let datas = JSON.parse(localStorage.getItem("boxUserInfo"));
      this.user_id = datas.id;
      let params = {
        page: this.page_num,
        pagesize: 21,
        user_id: this.user_id,
      };
      const res = await getsynthesisData(params);
      let data = res.data.data.data;
      this.newlistData = {
        total: res.data.data.total,
        pageSize: Number(res.data.data.per_page),
        current_page: res.data.data.current_page,
        list: data.map((item) => {
          return {
            ...item,
            height: "0.4rem",
          };
        }),
      };
    },
    async getdanyaodata() {
      let datas = JSON.parse(localStorage.getItem("boxUserInfo"));
      this.user_id = datas.id;
      let params = {
        page: this.page_num,
        pagesize: 21,
        user_id: this.user_id,
      };
      const res = await getammunitionData(params);
      let data = res.data.data.data;
      this.newlistData = {
        total: res.data.data.total,
        pageSize: Number(res.data.data.per_page),
        current_page: res.data.data.current_page,
        list: data.map((item) => {
          return {
            ...item,
            height: "0.4rem",
          };
        }),
      };
    },
    priceChange(v) {
      this.page_num = v.page;
      this.getrecord(this.pvalue, this.page_num);
    },
    pointchangeSelect(v) {
      this.pointalue = v.pointalue;
      this.pointtype = v.pointalue;
      if (v.value == 0) {
        this.pointtype = null;
      }
      this.getpointlistdata();
    },
    pricechangeSelect(v) {
      this.pvalue = v.pvalue;
      this.curtype = v.pvalue;
      if (v.value == 0) {
        this.curtype = null;
      }
      this.getrecord(this.curtype, 1);
    },
    changeSelect(v) {
      this.value = v.value;
      if (v.value == "2") {
        this.PickCode = 1;
        this.GetTakingList(1, this.PickCode);
      }
      if (v.value == "1") {
        this.PickCode = 9;
        this.GetTakingList(1, this.PickCode);
      }
    },
    //提货列表分页
    PickChange(val) {
      this.GetTakingList(val.page, this.PickCode);
    },
    // 获取到最新的钱包记录
    getrecord(num, page) {
      Recharge_record(num, page).then((res) => {
        this.tableData.list = res.data.data.data;
        this.tableData.total = res.data.data.total;
        this.tableData.pageSize = res.data.data.per_page;
        this.tableData.current_page = res.data.data.current_page;
      });
    },
    // 仓库列表分页
    handleCurrentChange(val) {
      this.GetStorage(val.page);
      this.page_Num = val.page;
    },

    typechange(v) {
      this.actypeindex = v;
      if (v === 2) {
        this.getrecord(this.curtype);
      } else if (v === 3) {
        this.GetTakingList(1, this.PickCode);
      } else if (v === 4) {
        this.page_num = 1;
        this.getdanyaodata();
      } else if (v === 5) {
        this.page_num = 1;
        this.getronliandata();
      } else if (v === 1) {
        this.GetStorage();
      } else if (v === 6) {
        this.getpointlistdata();
      }
    },
    //全选
    SelectAll(v) {
      if (this.IS_Select_All == false) {
        const array = [
          ...new Set([...this.chooseIdList, ...this.WarehouseIdLists]),
        ];
        this.chooseIdList = array;
      } else {
        // this.chooseIdList = this.chooseIdList.splice()
        this.chooseIdList = this.chooseIdList.filter((item) => {
          return !this.WarehouseIdLists.includes(item);
        });
      }
    },
    //多选提取
    WholeExtract() {
      let data = this.chooseIdList;
      Extract(data).then((res) => {
        if (res.data.code == 200) {
          this.num = 0;
          this.bean = 0;
          this.$message({
            message: res.data.message,
            type: "success",
          });
          this.GetPersonalInfo();
          this.GetStorage(this.page_Num);
          // this.Select_All = false;
        } else {
          this.$message({
            message: res.data.message,
            type: "error",
          });
        }
      });
    },

    //获取仓库列表(第一页)
    GetStorage(num) {
      this.loadingShow = true;
      Storage(num, this.sort).then((res) => {
        if (res.data.data.data.length > 0) {
          this.WarehouseLists = res.data.data.data;
          this.total = Number(res.data.data.total);
          this.per_page = Number(res.data.data.per_page);
          this.page_Num = Number(res.data.data.current_page);
        } else {
          if (num == "1") {
            this.WarehouseLists = res.data.data.data;
            this.total = Number(res.data.data.total);
            this.per_page = Number(res.data.data.per_page);
            this.page_Num = Number(res.data.data.current_page);
            this.loadingShow = false;
          } else {
            this.GetStorage("1");
          }
        }
      });
    },
    //选中
    itemOnClick(item, index) {
      if (this.chooseIdList.includes(item.id)) {
        this.chooseIdList.splice(this.chooseIdList.indexOf(item.id), 1);
        this.bean -= item.bean * 1;
        this.num -= 1;
      } else {
        this.bean += item.bean * 1;
        this.num += 1;
        this.chooseIdList.push(item.id);
      }
    },
    //个人信息
    GetPersonalInfo() {
      PersonalInfo().then((res) => {
        this.User(res.data.data);
      });
    },
    ...mapMutations(["User"]),

    // 提货列表
    GetTakingList(num, code) {
      Storage(num, 0, code).then((res) => {
        this.acceptData.total = res.data.data.total;
        this.acceptData.pager = res.data.data.per_page;
        this.acceptData.list = res.data.data.data;
        this.acceptData.current_page = res.data.data.current_page;
      });
    },
  },
  watch: {
    WarehouseLists(val) {
      setTimeout(() => {
        this.loadingShow = false;
      }, 100);
      val.forEach((item) => {
        this.AllWarehouseObj[item.id + ""] = item;
        if (!this.chooseIdList.includes(item.id)) {
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.contentbox {
  width: 100%;
  position: relative;
  .typebox {
    width: 100%;
    display: flex;
    .typeitem {
      width: 1.2rem;
      padding: 0.32rem 0 0.08rem 0;
      font-size: 0.18rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #636363;
      line-height: 0.21rem;
    }
    .actype {
      color: #c5bbed;
    }
  }
  .pullbox {
    position: absolute;
    right: 0;
    top: 0.27rem;
    .selectbox {
      border: 0.01rem solid #2f2f5e;
      .select-box {
        width: 2.04rem;
        height: 0.4rem;
        background: #08080f;

        position: relative;
        .input-value {
          width: 100%;
          height: 100%;
          line-height: 0.4rem;
          padding-left: 0.2rem;
          box-sizing: border-box;
          font-size: 0.16rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #c5bbed;
          overflow: hidden;
          white-space: nowrap;
        }
        .row {
          position: absolute;
          top: 0.1rem;
          right: 0.2rem;
          z-index: 1;
          img {
            width: 0.26rem;
            height: 0.24rem;
          }
        }

        .float-box {
          position: absolute;
          left: -1px;
          background: #08080f;
          z-index: 99;
          border: 0.01rem solid #2f2f5e;
          border-top: 0;
          .float-box-item {
            width: 2.04rem;
            height: 0.34rem;
            line-height: 0.34rem;
            padding: 0 0.2rem;
            box-sizing: border-box;
            font-size: 0.16rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #c5bbed;
            white-space: nowrap;
            overflow: hidden;
          }
          .float-box-item:hover {
            background: #2f2f5e;
            color: #c5bbed;
          }
        }
      }
    }
  }
  .depotcontent {
    width: 100%;
    .contentbox1 {
      width: 100%;
      height: 9.5rem;
      background: #08080f;
      border: 0.02rem solid #2f2f5e;
      .dcenter {
        width: 9.98rem;
        margin: 0 auto;
        .clickbox {
          width: 100%;
          height: 0.6rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .lbox {
            display: flex;
            align-items: center;
            .clickall {
              display: flex;
              align-items: center;
              img {
                width: 0.14rem;
                height: 0.14rem;
                margin-right: 0.14rem;
              }
              p {
                font-size: 0.16rem;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #8a8ace;
              }
            }
            .checktext {
              display: flex;
              margin-left: 0.3rem;
              p {
                font-size: 0.14rem;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                margin-right: 0.2rem;
              }
            }
          }
          .rbox {
            width: 1.64rem;
            display: flex;
            justify-content: space-between;
            .btn1 {
              width: 0.74rem;
              height: 0.3rem;
              text-align: center;
              line-height: 0.3rem;
              font-size: 0.14rem;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              background: #e55325;
              border: 0.01rem solid #e69379;
            }
            .btn2 {
              width: 0.74rem;
              height: 0.3rem;
              text-align: center;
              line-height: 0.3rem;
              font-size: 0.14rem;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              background: #5638cc;
              border: 0.01rem solid #8684ff;
            }
          }
        }
        .depotlist {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          margin-top: 0.32rem;
          justify-content: flex-start;
          .depotdata {
            width: 1.48rem;
            height: 1.78rem;
            background: url("../../assets/images/NewUser/pc-newuser-depotitembg.png")
              no-repeat;
            background-size: 100% 100%;
            margin-bottom: 0.2rem;
            margin-right: 1.8%;
            .durabox {
              text-align: center;
              height: 0.22rem;
              line-height: 0.22rem;
              font-size: 0.1rem;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              color: #adadad;
            }
            .imgbox {
              width: 100%;
              height: 1.05rem;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                display: block;
                width: 1.16rem;
                margin: 0 auto;
              }
            }
            .itemname {
              text-align: center;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 0.12rem;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              margin-top: 0.1rem;
              padding: 0 0.1rem;
              position: relative;
            }
            .showtextbox {
              position: absolute;
              background: #232338;
              text-align: center;
              padding: 0.1rem;
              line-height: 0.2rem;
            }
            .itembean {
              font-size: 0.14rem;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              margin-top: 0.04rem;
            }
          }
          .Warehouse-List-item-active {
            background: url("../../assets/images/NewUser/pc-newuser-acdepotitembg.png")
              no-repeat center center;
            background-size: 100% 100%;
          }

          .item-ac {
            background: url("../../assets/images/NewUser/pc-newuser-acdepotitembg.png")
              no-repeat center center;
            background-size: 100% 100%;
          }
        }
      }
    }
  }
  .listitem {
    overflow: hidden; /* 隐藏超出容器的内容 */
    text-overflow: ellipsis; /* 当文本溢出时显示省略号 */
    white-space: nowrap; /* 防止文本换行 */
    margin-right: 0.2rem;
    position: relative;
    height: 0.4rem;
    line-height: 0.4rem;
    text-align: left;
  }
  .showlist {
    position: absolute;
    width: 2.7rem;
    height: 2.7rem;
    background: url("../../assets/images/about/user/listnamebg.png") no-repeat;
    background-size: 100% 100%;
    padding-top: 0.05rem;
    z-index: 3;
    overflow-y: scroll;
    .putlist {
      font-weight: 400;
      font-size: 0.16rem;
      color: #ffffff;
      margin: 0.05rem 0;
    }
  }
  .showlist2 {
    position: absolute;
    width: 2.7rem;
    height: 0.8rem;
    background: url("../../assets/images/about/user/listnamebg.png") no-repeat;
    background-size: 100% 100%;
    padding-top: 0.05rem;
    z-index: 3;
    overflow-y: scroll;
    .putlist {
      font-weight: 400;
      font-size: 0.16rem;
      color: #ffffff;
      margin: 0.05rem 0;
    }
  }
  .elsbox {
    overflow: hidden; /* 隐藏超出容器的内容 */
    text-overflow: ellipsis; /* 当文本溢出时显示省略号 */
    white-space: nowrap; /* 防止文本换行 */
  }
}
</style>
