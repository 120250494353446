<template>
  <div class="contentbox">
    <div class="content">
      <div class="contentbox1">
        <div class="paytypebox">
          <div
            class="paytypeitem point"
            v-for="(item, index) in typedata"
            :key="index"
            :class="{ acpaytypeitem: actypeindex == index }"
            @click="typechange(item, index)"
          >
            <div class="paytypeinfo">
              <p>{{ item.name }}</p>
              <div class="payiconbox">
                <img
                  :src="icons"
                  alt=""
                  v-for="(icons, index) in item.image"
                  :key="index"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="center">
          <div class="tipsbox">
            <div class="tipstitle">
              <img
                src="../../assets/images/about/recharge/tipsicon.png"
                alt=""
              />
              充值说明：
            </div>
            <div class="tipstext">
              <p v-html="this.global_info.pay_one_info"></p>
              <p v-html="this.global_info.pay_more_info"></p>
            </div>
          </div>
          <div class="putbox">
            <input
              class="putinfo"
              placeholder="请输入充值数额"
              type="text"
              v-model.number="pricevalue"
              @blur="validateAndRound"
            />
          </div>
          <div class="check-box">
            <img
              class="point"
              src="../../assets/images/NewUser/pc-newuser-depotclick.png"
              v-show="!status"
              alt=""
              @click="() => (this.status = true)"
            />
            <img
              class="point"
              src="../../assets/images/NewUser/pc-newuser-depotacclick.png"
              alt=""
              v-show="status"
              @click="() => (this.status = false)"
            />
            本人已满18周岁且具备完全行为能力,充值即代表同意《条款和条例》《隐私政策》。盲盒快乐请理性消费,请勿上头
          </div>

          <div class="paytext">
            支付金额（人民币）约：
            <p>{{ pricevalue * 6.5 }}</p>
          </div>
          <div
            class="tips"
            v-if="querydata.pay_qq != ''"
            v-html="querydata.pay_qq"
          ></div>
          <div class="paybtnbox">
            <div class="paybtn point" @click="dopay">立即支付</div>
          </div>
          <!-- <div class="keyput point">
            <input type="text" v-model="cdkey" />
            <div class="buybtn" @click="PostCard_recharge">卡密充值</div>
          </div> -->
        </div>
      </div>
    </div>
    <van-overlay @mousewheel.prevent.native="() => {}" :show="payurlshow">
      <div class="paybox" :show="payurlshow">
        <div class="titlebox">
          <p>支付订单</p>
          <img
            class="closeicon point"
            src="../../assets/images/mall/pc-mall-close.png"
            alt=""
            @click="payClose"
          />
        </div>
        <div class="line"></div>
        <div class="payewmbox">
          <div>
            <div class="ewmbox">
              <img class="payurl" :src="payurl" alt="" />
            </div>
            <div class="typeimg">
              <img
                class="payicon"
                :src="item"
                v-for="(item, index) in this.payimg"
                :key="index"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import {
  Recharge,
  Beans,
  Card_recharge,
  Recharge_record,
  Info,
} from "@/network/api.js";
import { mapState, mapActions } from "vuex";
import QRCode from "qrcode";
export default {
  data() {
    return {
      actypeindex: -1,

      priceList: [{}],
      priceCurrent: -1, //选中金额
      acprice: 0,
      status: false,
      id: "",
      pay_way: 3, //选择支付方式
      status: false, //购买确认
      payurl: "",
      payurlshow: false, //支付二维码显示
      // order_no: "", //支付订单的状态码
      query_url: "", // 新支付回调二维码
      Timer: null,
      orderInfo: "",
      rechangedata: 0, //是否首充状态
      cdkey: "",
      value: "0",
      page_num: 1,
      curtype: null,
      querydata: {
        pay_qq: "",
      },
      payHtml: "",

      pricevalue: 5, // 输入充值金额
      paytype: "",
      payimg: "",
      max_num: 300,
      clicktype: true,
    };
  },

  created() {
    // const res = JSON.parse(localStorage.getItem("boxUserInfo"))
    // this.getBeans();
    this.GetInfo();
  },
  computed: {
    ...mapState(["user", "global_info"]),
    typedata() {
      return this.global_info.pay_channel;
    },
  },
  methods: {
    GetInfo() {
      Info().then((res) => {
        this.querydata = res.data.data;
      });
    },

    typechange(v, i) {
      this.max_num = v.price_max;
      this.paytype = v.type;
      this.payimg = v.image;
      this.actypeindex = i;
    },

    ...mapActions({
      MyInfo: "Refresh",
    }),
    //卡密支付
    PostCard_recharge() {
      Card_recharge(this.cdkey).then((res) => {
        if (res.data?.code === 200) {
          this.$message({
            message: "兑换成功",
            type: "success",
          });
          this.MyInfo();
          this.cdkey = "";
        } else {
          this.$message({
            message: res.data?.message,
            type: "error",
          });
        }
      });
    },
    // // 选中充值金额
    // priceChange(v, i) {
    //   this.priceCurrent = i; //样式切换
    //   this.acprice = v.price; //需要支付金额
    //   this.id = v.id;
    // },
    // // 切换充值方式
    // currentChange(item) {
    //   this.pay_way = item;
    //   this.priceCurrent = -1; //样式切换
    //   this.acprice = ""; //需要支付金额
    //   this.id = "";

    // },
    payClose() {
      if (this.Timer) {
        clearInterval(this.Timer);
      }
      this.payurlshow = false;
    },
    // 当前充值
    dopay() {
      let info = this.user;
      if (!info.true_name) {
        this.$message({
          message: "还未实名认证，请认证后操作",
          type: "error",
        });
        this.$emit("truename", true);
      } else {
        if (!this.paytype) {
          this.$message({
            message: "请选择充值渠道",
            type: "error",
          });
          return false;
        }
        if (!this.pricevalue) {
          this.$message({
            message: "请输入充值金额",
            type: "error",
          });
          return false;
        }
        if (this.status == false) {
          this.$message({
            message: "请选择充值协议",
            type: "error",
          });
        } else {
          if (this.clicktype) {
            this.clicktype = false;
            setTimeout(() => {
              this.clicktype = true;
            }, 3000);
            this.validateAndRound();
            let parmes = {
              type: this.paytype,
              money: this.pricevalue,
            };
            Recharge(parmes).then((res) => {
              if (res.data.code == 200) {
                if (res.data.data?.list.is_html) {
                  // 打开一个新窗口
                  var newWindow = window.open(
                    res.data.data?.list.content,
                    "_self"
                  );
                  // 关闭document对象
                  newWindow.document.close();
                } else {
                  this.payurl = res.data.data?.list?.qr_url;
                  if (res.data.data?.list?.is_qrcode) {
                    QRCode.toDataURL(this.payurl)
                      .then((url) => {
                        this.payurl = url;
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                  this.payurlshow = true;
                }
                // this.query_url = res.data.data.list.query_url;
                // this.order_no = res.data.data.order_no;
                // this.Timer = window.setInterval(() => {
                //   setTimeout(this.queryAPI(), 0);
                // }, 2000);
              } else {
                this.$message({
                  message: res.data.message,
                  type: "error",
                });
              }
            });
          } else {
            this.$message({
              message: "你点的太快啦",
              type: "error",
            });
          }
        }
      }
    },
    // 获取订单状态api
    queryAPI() {
      const url = this.query_url;
      fetch(url, {})
        .then((resp) => resp.json())
        .then((data) => {
          //解析 {data}
          console.log(666666, data);
          if (data.status === "succeeded") {
            this.$message({
              message: "支付成功",
              type: "success",
            });
            if (this.Timer) {
              clearInterval(this.Timer);
            }
            this.payurlshow = false;
            this.MyInfo();
          }
        })
        .catch((err) => {
          this.$message({
            message: "支付失败",
            type: "error",
          });
          if (this.Timer) {
            clearInterval(this.Timer);
          }
          this.payurlshow = false;
          this.MyInfo();
        });
    },
    // 获取到价格列表
    getBeans() {
      Beans().then((res) => {
        let data = res.data.data;
        this.priceList = data;
      });
      // let data = JSON.parse(localStorage.getItem("boxUserInfo"));
      // this.rechangedata = data.is_recharge;
    },
    validateAndRound() {
      const num = Number(this.pricevalue);
      if (isNaN(num) || num < 5 || num > this.max_num) {
        this.$message({
          message: "请输入有效的金额最少5最大" + `${this.max_num}`,
          type: "error",
        });
        if (num < 5) {
          this.pricevalue = 5;
        } else if (num > this.max_num) {
          this.pricevalue = this.max_num;
        }
      } else {
        this.pricevalue = Math.round(num); // 取整
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contentbox {
  width: 100%;
  position: relative;
  .typebox {
    width: 100%;
    display: flex;
    height: 0.32rem;
    .typeitem {
      width: 1.2rem;
      padding: 0.32rem 0 0.08rem 0;
      font-size: 0.18rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #636363;
      line-height: 0.21rem;
    }
    .actype {
      color: #c5bbed;
    }
  }
  .pullbox {
    position: absolute;
    right: 0;
    top: 0.27rem;
    .selectbox {
      .select-box {
        width: 2.04rem;
        height: 0.4rem;
        background: #08080f;
        border: 0.01rem solid #2f2f5e;
        position: relative;
        .input-value {
          width: 100%;
          height: 100%;
          line-height: 0.4rem;
          padding-left: 0.2rem;
          box-sizing: border-box;
          font-size: 0.16rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #c5bbed;
          overflow: hidden;
          white-space: nowrap;
        }
        .row {
          position: absolute;
          top: 0.1rem;
          right: 0.2rem;
          z-index: 1;
          img {
            width: 0.26rem;
            height: 0.24rem;
          }
        }

        .float-box {
          position: absolute;
          left: -1px;
          background: #08080f;
          z-index: 99;
          border: 0.01rem solid #2f2f5e;
          border-top: 0;
          .float-box-item {
            width: 2.04rem;
            height: 0.34rem;
            line-height: 0.34rem;
            padding: 0 0.2rem;
            box-sizing: border-box;
            font-size: 0.16rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #c5bbed;
            white-space: nowrap;
            overflow: hidden;
          }
          .float-box-item:hover {
            background: #2f2f5e;
            color: #c5bbed;
          }
        }
      }
    }
  }
  .content {
    width: 100%;
    .contentbox1 {
      width: 100%;
      height: 9.5rem;
      background: #08080f;
      border: 0.02rem solid #2f2f5e;
      margin-top: 0.2rem;
      .pay-list {
        width: 7.2rem;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 0.35rem;

        .pay-item {
          width: 1.4rem;
          height: 1.4rem;
          border-radius: 50%;
          background: url("../../assets/images/NewUser/pc-newuser-pursespaybg.png")
            no-repeat;
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.32rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          cursor: pointer;
          position: relative;
          margin-right: 0.4rem;
          .recharge {
            position: absolute;
            top: -0.1rem;
            left: -0.1rem;

            img {
              display: block;
              width: 1.1rem;
            }
          }
        }

        .pay-item-ac {
          background: url("../../assets/images/NewUser/pc-newuser-acpursespaybg.png")
            no-repeat;
          background-size: 100% 100%;
        }
      }
      .center {
        width: 9.82rem;
        margin: 0 auto;
        .check-box {
          display: flex;
          align-items: center;
          margin-top: 0.3rem;
          font-size: 0.16rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #8080b5;
          img {
            width: 0.16rem;
            height: 0.16rem;
            margin-right: 0.2rem;
          }
        }
        .paytext {
          margin-top: 0.47rem;
          font-size: 0.18rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          display: flex;
          align-items: center;
          p {
            font-size: 0.22rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #efa900;
          }
        }
        .tips {
          margin-top: 0.36rem;
          font-size: 0.18rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #efa900;
        }
        .paybtnbox {
          display: flex;
          margin-top: 0.36rem;
          .paybtn {
            width: 1.22rem;
            height: 0.48rem;
            background: linear-gradient(135deg, #242448 0%, #42426d 100%);
            border: 0.01rem solid #8686db;
            text-align: center;
            line-height: 0.48rem;
            font-size: 0.2rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #ffffff;
          }
          .paytype {
            display: flex;
            margin-left: 0.16rem;
            .payicon {
              width: 0.48rem;
              height: 0.48rem;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 0.36rem;
                height: 0.36rem;
              }
            }
            .acicon {
              border-radius: 50%;
              background: #08080f;
              border: 0.01rem solid #8585db;
            }
          }
        }
        .keyput {
          margin-top: 0.5rem;
          display: flex;

          input {
            width: 7.4rem;
            height: 0.4rem;
            border: 0.01rem solid #2f2f5e;
            background: #101023;
          }
          .buybtn {
            margin-left: 0.3rem;
            width: 1.22rem;
            height: 0.4rem;
            text-align: center;
            line-height: 0.4rem;
            background: linear-gradient(90deg, #242448 0%, #42426d 100%);
            border: 0.01rem solid #8686db;
          }
        }
      }
      .paytypebox {
        width: 100%;
        height: 0.7rem;
        display: flex;
        align-items: center;
        border-bottom: 0.01rem solid rgba(47, 47, 94, 0.6);
        .paytypeitem {
          padding: 0 0.02rem;
          height: 100%;
          font-weight: 400;
          font-size: 0.2rem;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          .paytypeinfo {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            img {
              width: 0.2rem;
              height: 0.2rem;
              border-radius: 50%;
              margin-right: 0.1rem;
            }
            .payiconbox {
              margin-top: 0.1rem;
              display: flex;
              align-items: center;
              width: 100%;
              justify-content: center;
            }
          }
        }
        .acpaytypeitem {
          background: url("../../assets/images/NewUser/acpaytypebg.png")
            no-repeat;
          background-size: 100% 100%;
        }
      }
      .tipsbox {
        width: 100%;
        margin-top: 0.27rem;
        .tipstitle {
          display: flex;
          align-items: center;
          img {
            width: 0.24rem;
            height: 0.24rem;
            margin-right: 0.12rem;
          }
        }
        .tipstext {
          width: 5.4rem;
          margin-top: 0.1rem;
          font-weight: 400;
          font-size: 0.16rem;
          color: #9292d7;
          line-height: 0.24rem;
        }
      }
      .putbox {
        width: 100%;
        margin-top: 0.36rem;
        .putinfo {
          width: 5.4rem;
          height: 0.5rem;
          border: 0.01rem solid rgba(93, 93, 155, 0.5);
          background: rgba(5, 5, 18, 1);
          font-weight: 300;
          font-size: 0.2rem;
          color: rgba(191, 191, 237, 0.5);
          text-indent: 0.2rem;
          border-radius: 0.04rem;
        }
      }
    }
  }
  .paybox {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 6rem;
    height: 3.2rem;
    background: #09091f;
    border: 0.02rem solid #5d5d9b;
    border-radius: 0.04rem;
    .titlebox {
      width: 100%;
      height: 0.51rem;
      line-height: 0.51rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 0.2rem;
      color: #ffffff;
      text-align: center;
      position: relative;
      .closeicon {
        width: 0.16rem;
        height: 0.16rem;
        position: absolute;
        right: 0.15rem;
        top: 0.15rem;
      }
    }
    .line {
      width: 100%;
      height: 0;
      border: 0.01rem solid;
      border-image: linear-gradient(
          90deg,
          rgba(93, 93, 155, 0),
          rgba(93, 93, 155, 1),
          rgba(93, 93, 155, 0)
        )
        1 1;
    }
    .payewmbox {
      width: 100%;
      height: 2.7rem;
      display: flex;
      justify-content: center;
      align-items: center;
      .ewmbox {
        width: 1.6rem;
        height: 1.5rem;
        background: url("../../assets/images/NewUser/pc-newuser-payurlbg.png")
          no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        .payurl {
          width: 1.27rem;
          height: 1.27rem;
        }
      }
      .typeimg {
        width: 100%;
        height: 0.91rem;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 0.48rem;
          height: 0.48rem;
          margin: 0 0.1rem;
        }
      }
    }
  }
}
</style>
