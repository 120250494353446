<template>
  <div>
    <div id="newuserbox">
      <div class="lbox">
        <div class="logouticon point" @click="loginOut()">
          <img
            src="../../assets/images/NewUser/pc-newuser-logout.png"
            @mouseenter="etext"
            @mouseleave="ltext"
            alt=""
          />
          <p v-show="loginouttext">退出登录</p>
        </div>
        <div class="lcenter">
          <div class="logintime">上次登录：{{ updated_at }}</div>
          <div class="userinfo">
            <div class="userhead">
              <div class="headimg point" @click="toggleShow">
                <img :src="avatar" alt="" />
              </div>
            </div>
            <div class="username1">{{ Name }}</div>
            <div class="userphone">
              <div class="phonetext">
                {{ !phoneShow ? restPhone : mobile }}
              </div>
              <img
                class="point"
                v-if="phoneShow == false"
                src="@/assets/images//NewUser/pc-newuser-eyeclose.png"
                alt=""
                @click="phoneShow = !phoneShow"
              />
              <img
                class="point"
                v-if="phoneShow == true"
                src="@/assets/images/NewUser/pc-newuser-eyeshow.png"
                alt=""
                @click="phoneShow = !phoneShow"
              />
            </div>
            <div class="username">
              <div class="phonetext">
                ID:
                {{
                  !userIdShow
                    ? user_id.replace(user_id.substring(1, 5), "****")
                    : user_id
                }}
              </div>
              <img
                class="point"
                v-if="userIdShow == false"
                src="@/assets/images//NewUser/pc-newuser-eyeclose.png"
                alt=""
                @click="userIdShow = !userIdShow"
              />
              <img
                class="point"
                v-if="userIdShow == true"
                src="@/assets/images/NewUser/pc-newuser-eyeshow.png"
                alt=""
                @click="userIdShow = !userIdShow"
              />
            </div>
            <div class="changeitem">
              <div
                class="changeicon"
                v-for="item in icondata"
                :key="item.id"
                @click="iconclick(item.id)"
                @mouseenter="icontitle(item.title, item.id)"
                @mouseleave="leacveicon"
              >
                <img :src="item.img" alt="" />
                <p class="changetitle" v-if="actitle == item.id">
                  {{ icontitlevalu }}
                </p>
              </div>
            </div>
          </div>
          <!-- <div class="infolist">
            总充值<Cprice :price="Totalrecharge"></Cprice>
          </div>
          <div class="infolist">
            月充值<Cprice :price="monthlyrecharge"></Cprice>
          </div>
          <div class="infolist">
            总取回<Cint :price="Totalretrieval"></Cint>
          </div>
          <div class="infolist">
            月取回<Cint :price="monthlyretrieval"></Cint>
          </div> -->

          <div class="putbox">
            <div class="puttitle">Steam交易链接</div>
            <input
              class="put"
              type="text"
              v-if="user.steam_url == ''"
              disabled="disabled"
              placeholder="请设置您的steam交易链接"
            />
            <!-- <div v-if="user.steam_url !== ''">{{steam_url}}</div> -->
            <input
              class="put"
              type="text"
              v-if="user.steam_url !== ''"
              disabled="disabled"
              v-model="steam_url"
            />
            <div class="btnbox">
              <div class="putbtn point" @click="showsteam">设置</div>
            </div>
          </div>

          <div class="putbox">
            <div class="puttitle">上级邀请码</div>
            <input
              class="put"
              type="text"
              v-if="inviter_id == 0"
              v-model="invite_code_bangding"
              placeholder="请输入您的邀请码"
            />
            <input
              class="put"
              type="text"
              disabled="disabled"
              v-if="inviter_id !== 0"
              v-model="invite_code2"
            />
            <div class="btnbox">
              <div
                class="putbtn point"
                @click="setinvite_code"
                v-if="inviter_id == 0"
              >
                保存
              </div>
              <div
                class="putbtn"
                style="-webkit-filter: grayscale(0.95)"
                v-if="inviter_id !== 0"
              >
                保存
              </div>
            </div>
          </div>
          <div style="margin-top: 0.21rem">
            <div class="helpebox">
              <img
                src="../../assets/images/NewUser/pc-newuser-helpe.png"
                alt=""
              />
              <div class="msg">打不开Steam怎么办？</div>
              <a
                class="clickhelpe"
                target="_block"
                href="https://www.bilibili.com/video/BV15D4y1X79w/"
                >查看教程</a
              >
            </div>
            <div class="helpebox">
              <img
                src="../../assets/images/NewUser/pc-newuser-helpe.png"
                alt=""
              />
              <div class="msg">获取你的Steam链接</div>
              <a
                class="clickhelpe"
                target="_block"
                href="https://steamcommunity.com/profiles/76561198294948713/tradeoffers/privacy#trade_offer_access_url"
                >点击获取</a
              >
            </div>
            <div class="helpebox">
              <img
                src="../../assets/images/NewUser/pc-newuser-helpe.png"
                alt=""
              />
              <div class="msg" style="width: 74%">
                取回前请将您的库存设置为" 公开 "
              </div>
              <a
                class="clickhelpe"
                target="_block"
                href="https://steamcommunity.com/profiles/76561198294948713/edit/settings/"
                >查看</a
              >
            </div>
          </div>
          <!-- 手机绑定 -->
          <van-overlay
            @mousewheel.prevent.native="() => {}"
            :show="phonebindshow"
          >
            <div class="namebindbox">
              <div v-if="put1">
                <div class="titlebox">
                  <p>手机绑定</p>
                  <img
                    class="closeicon point"
                    src="../../assets/images/mall/pc-mall-close.png"
                    alt=""
                    @click="() => (this.phonebindshow = false)"
                  />
                </div>
                <div class="line"></div>
                <div class="namecenter">
                  <div class="changeputbox">
                    <span>手机号码:</span>
                    <input
                      class="putcalss"
                      type="text"
                      v-model.number="phonevalue"
                      placeholder="请输入手机号"
                    />
                  </div>
                  <div class="changeputbox yzmputbox">
                    <span>验证码:</span>
                    <input
                      class="putcalss"
                      type="text"
                      v-model="phoneyzm"
                      placeholder="请输入验证码"
                    />
                    <div class="yzmbtn point" @click="getyzm">获取验证码</div>
                  </div>
                </div>
                <div class="updatabtnbox">
                  <div class="updatabtn" @click="doenter">下一步</div>
                </div>
              </div>
              <div v-else-if="put2">
                <div class="titlebox">
                  <p>绑定结果</p>
                  <img
                    class="closeicon point"
                    src="../../assets/images/mall/pc-mall-close.png"
                    alt=""
                    @click="() => (this.phonebindshow = false)"
                  />
                </div>
                <div class="line"></div>
                <div class="okbox">
                  <div class="iconinfo">
                    <img
                      src="../../assets/images/NewUser/pc-newuser-okicon.png"
                      alt=""
                    />
                    <p>绑定成功</p>
                  </div>
                </div>
                <div class="updatabtnbox">
                  <div
                    class="updatabtn"
                    @click="() => (this.phonebindshow = false)"
                  >
                    下一步
                  </div>
                </div>
              </div>
            </div>
          </van-overlay>
          <!-- steam验证绑定 -->
          <van-overlay
            @mousewheel.prevent.native="() => {}"
            :show="setsteamshow"
          >
            <div class="namebindbox passwordbox">
              <div v-if="put1">
                <div class="titlebox">
                  <p>Steam绑定</p>
                  <img
                    class="closeicon point"
                    src="../../assets/images/mall/pc-mall-close.png"
                    alt=""
                    @click="() => (this.setsteamshow = false)"
                  />
                </div>
                <div class="line"></div>
                <div class="namecenter">
                  <div class="changeputbox">
                    <span>手机号码:</span>
                    <input
                      class="putcalss"
                      type="text"
                      v-model.number="phonevalue"
                      placeholder="请输入手机号"
                    />
                  </div>
                  <div class="changeputbox yzmputbox">
                    <span>验证码:</span>
                    <input
                      class="putcalss"
                      type="text"
                      v-model="steamphoneyzm"
                      placeholder="请输入验证码"
                    />
                    <div class="yzmbtn point" @click="getsteamyzm">
                      获取验证码
                    </div>
                  </div>
                  <div class="changeputbox">
                    <span>输入Steam:</span>
                    <input
                      class="putcalss"
                      type="text"
                      v-model="steam_url"
                      placeholder="请输入Steam连接"
                    />
                  </div>
                </div>
                <div class="updatabtnbox">
                  <div class="updatabtn" @click="GetSteamUrl">确认</div>
                </div>
              </div>
            </div>
          </van-overlay>
          <!-- 实名认证 -->
          <van-overlay
            @mousewheel.prevent.native="() => {}"
            :show="namebindshow"
          >
            <div class="namebindbox" v-show="namebindshow">
              <div v-if="put1">
                <div class="titlebox">
                  <p>实名认证</p>
                  <img
                    class="closeicon point"
                    src="../../assets/images/mall/pc-mall-close.png"
                    alt=""
                    @click="() => (this.namebindshow = false)"
                  />
                </div>
                <div class="line"></div>
                <div class="namecenter">
                  <div class="changeputbox">
                    <span>真实姓名:</span>
                    <input
                      class="putcalss"
                      type="text"
                      v-model="namevalue"
                      placeholder="请输入真实姓名"
                    />
                  </div>
                  <div class="changeputbox">
                    <span>身份证号:</span>
                    <input
                      class="putcalss"
                      type="text"
                      v-model="nameID"
                      placeholder="请输入真实身份证号"
                    />
                  </div>
                </div>
                <div class="updatabtnbox">
                  <div class="updatabtn" @click="doentername">下一步</div>
                </div>
              </div>
              <!-- 认证结果 -->
              <div v-else-if="put2">
                <div class="titlebox">
                  <p>认证结果</p>
                  <img
                    class="closeicon point"
                    src="../../assets/images/mall/pc-mall-close.png"
                    alt=""
                    @click="
                      () => {
                        (this.namebindshow = false), (this.put2 = false);
                      }
                    "
                  />
                </div>
                <div class="line"></div>
                <div class="okbox">
                  <div class="iconinfo">
                    <img
                      src="../../assets/images/NewUser/pc-newuser-okicon.png"
                      alt=""
                    />
                    <p>认证成功</p>
                  </div>
                </div>
                <div class="updatabtnbox">
                  <div
                    class="updatabtn"
                    @click="
                      () => {
                        (this.namebindshow = false), (this.put2 = false);
                      }
                    "
                  >
                    关闭
                  </div>
                </div>
              </div>
            </div>
          </van-overlay>
          <!-- 修改头像昵称 -->
          <van-overlay @mousewheel.prevent.native="() => {}" :show="usershow">
            <div class="userheadbox">
              <div class="changeheadbox">
                <div class="h_title">编辑资料</div>
                <div class="line"></div>
                <div class="a_content">
                  <div class="activeimgbox">
                    <div class="imgtitle">选择一个作为你的头像</div>
                    <div class="imgbox">
                      <div class="imgcenter">
                        <div class="addimg point" @click="uploadbease">
                          <img
                            src="../../assets/images/NewUser/pc-newuser-headadd.png"
                            alt=""
                          />
                          <input
                            style="display: none"
                            type="file"
                            id="upload"
                            accept="image/*"
                            @change="handleFileUpload($event)"
                          />
                        </div>
                        <div v-for="(v, i) in imgurlvalue" :key="i">
                          <div
                            class="l_imgbox point"
                            :class="changehead === i ? 'acimgbox' : ''"
                            @click="imgchange(v, i)"
                          >
                            <img :src="v.url" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="nikenamebox">
                    <div class="upimgbox">
                      <img :src="isupdataimg" alt="" />
                    </div>
                    <div class="putnamebox">
                      <input
                        class="putname"
                        v-model="namevalu"
                        type="text"
                        placeholder="玩家昵称"
                      />
                    </div>
                    <div class="enterbtnbox">
                      <div
                        class="namebtn point nb1"
                        @click="() => (this.usershow = false)"
                      >
                        取消
                      </div>
                      <div class="namebtn point nb2" @click="updata">保存</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-overlay>
          <!-- 修改密码 -->
          <van-overlay
            @mousewheel.prevent.native="() => {}"
            :show="passwordshow"
          >
            <div class="namebindbox passwordbox" v-show="passwordshow">
              <div v-if="put1">
                <div class="titlebox">
                  <p>修改密码</p>
                  <img
                    class="closeicon point"
                    src="../../assets/images/mall/pc-mall-close.png"
                    alt=""
                    @click="
                      () => {
                        (this.passwordshow = false), closedata();
                      }
                    "
                  />
                </div>
                <div class="line"></div>
                <div class="namecenter">
                  <div class="changeputbox">
                    <span>手机号码:</span>
                    <input
                      class="putcalss"
                      type="text"
                      v-model.number="phonevalue"
                      placeholder="请输入手机号"
                    />
                  </div>
                  <div class="changeputbox yzmputbox">
                    <span>验证码:</span>
                    <input
                      class="putcalss"
                      type="text"
                      v-model="passyzm"
                      placeholder="请输入验证码"
                    />
                    <div class="yzmbtn point" @click="getpassyzm">
                      获取验证码
                    </div>
                  </div>
                  <div class="changeputbox">
                    <span>设置新密码:</span>
                    <input
                      class="putcalss"
                      type="password"
                      v-model="newpass"
                      placeholder="请输入新密码"
                    />
                  </div>
                  <div class="changeputbox">
                    <span>确认新密码:</span>
                    <input
                      class="putcalss"
                      type="password"
                      v-model="enterpass"
                      placeholder="再次输入新密码"
                    />
                  </div>
                </div>
                <div class="updatabtnbox">
                  <div class="updatabtn" @click="dopass">下一步</div>
                </div>
              </div>
              <div v-else-if="put2">
                <div class="titlebox">
                  <p>修改结果</p>
                  <img
                    class="closeicon point"
                    src="../../assets/images/mall/pc-mall-close.png"
                    alt=""
                    @click="
                      () => {
                        this.passwordshow = false;
                      }
                    "
                  />
                </div>
                <div class="line"></div>
                <div class="okbox">
                  <div class="iconinfo">
                    <img
                      src="../../assets/images/NewUser/pc-newuser-okicon.png"
                      alt=""
                    />
                    <p>修改成功</p>
                  </div>
                </div>
                <div class="updatabtnbox">
                  <div class="updatabtn" @click="backlogin">返回首页</div>
                </div>
              </div>
            </div>
          </van-overlay>
        </div>
      </div>
      <div class="rbox">
        <div class="rcenter">
          <div class="titletab">
            <div
              class="tabitem point t1"
              :class="{ 't1-1': actitletab == 1 }"
              @click="changetab(1)"
            >
              我的背包
            </div>
            <div
              class="tabitem point t2"
              :class="{ 't2-2': actitletab == 2 }"
              @click="changetab(2)"
            >
              辉煌表现
            </div>
            <div
              class="tabitem point t3"
              :class="{ 't2-2': actitletab == 3 }"
              @click="changetab(3)"
            >
              充值
            </div>
            <div
              class="tabitem point t4"
              :class="{ 't3-3': actitletab == 4 }"
              @click="changetab(4)"
            >
              分享奖励
            </div>
          </div>
          <div class="content">
            <depot v-if="actitletab == 1" />
            <splendour v-if="actitletab == 2" />
            <purses v-if="actitletab == 3" @truename="getname" />
            <partake v-if="actitletab == 4" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 背包
import depot from "./depot.vue";
// 充值 钱包
import purses from "./Purses.vue";
// 分享
import partake from "./Partake.vue";
// 辉煌时刻
import splendour from "./Splendour.vue";
import { mapState, mapMutations, mapActions } from "vuex";
import {
  PostSendSms,
  SteamBind,
  Reset_password,
  PersonalInfo,
  SteamUrl,
  Set_Name,
  set_inviter,
  checkNameAndCardId,
  getrechargeinfoData,
  Set_avatar,
  base64,
} from "@/network/api.js";
var myreg = /^[1][2,3,4,5,7,8,6,9][0-9]{9}$/; //设置手机号正则表达式
var ID = /(^\d{15}$)|(^\d{17}([0-9]|X)$)/; //身份证正则
var reg = /^[\w]{6,12}$/; //密码正则最少6位最多12，包含字母数字

import { imgurlvalue } from "./index";
export default {
  data() {
    return {
      actitletab: 2,
      user_id: "", //用户id
      phoneShow: false,
      userIdShow: false,
      avatar: "",
      Name: "",
      // invite_code: "",
      Email: "",
      created_at: "",
      updated_at: "",
      mobile: "",

      setsteamshow: false,
      steamphoneyzm: "", //steam手机绑定验证码
      steam_url: "",
      steam_id: "",
      invite_code_bangding: "",
      inviter_id: 0,
      inviter: {},
      invite_code2: "",
      message: {},
      //实名认证相关
      namebindshow: false, //实名认证绑定
      namevalue: "", //姓名
      nameID: "", //身份证号码
      //手机绑定相关
      phonebindshow: false, //绑定手机弹窗
      put1: false, //绑定1
      put2: false, //绑定2
      put3: false, //绑定3
      phonevalue: "", //手机号
      phoneyzm: "", //手机绑定验证码

      passwordshow: false, //修改密码弹窗
      passyzm: "", //修改密码验证码
      newpass: "", //新密码
      enterpass: "", //确认新密码
      Totalrecharge: 0, //总充值金额
      monthlyrecharge: 0, //月充值
      Totalretrieval: 0, //总取回
      monthlyretrieval: 0, //月取回
      flag: true,

      isupdataimg: "", //更新时的头像显示
      usershow: false, //用户修改头像和修改昵称开关
      imgurlvalue, //模拟头像
      changehead: 0, //选择头像change
      namevalu: "", //用户输入的昵称
      file: null, //用户选择文件
      icondata: [
        {
          id: 1,
          img: require("../../assets/images/NewUser/pc-newuser-icon1.png"),
          title: "手机绑定",
        },
        {
          id: 2,
          img: require("../../assets/images/NewUser/pc-newuser-icon2.png"),
          title: "实名认证",
        },
        {
          id: 3,
          img: require("../../assets/images/NewUser/pc-newuser-icon3.png"),
          title: "修改密码",
        },
      ],
      icontitlevalu: "",
      actitle: null,
      loginouttext: false,
      isAllowRealName: true,
    };
  },
  components: {
    depot,
    purses,
    partake,
    splendour,
  },
  created() {
    if (this.$route.query.TabIndex) {
      this.actitletab = this.$route.query.TabIndex;
    } else {
      this.actitletab = 2;
    }
    this.GetPersonalInfo(this.user);
    this.getrecheargedata();
  },
  methods: {
    showsteam() {
      this.setsteamshow = !this.setsteamshow;
      this.put1 = true;
    },
    getname(v) {
      this.namebindshow = v;
      this.put1 = v;
    },
    closedata() {
      this.phonevalue = "";
      this.newpass = "";
      this.enterpass = "";
      this.passyzm = "";
    },
    ltext() {
      this.loginouttext = !this.loginouttext;
    },
    etext() {
      this.loginouttext = !this.loginouttext;
    },
    // 用户点击确认
    updata() {
      // 获取用户输入昵称
      let res = this.namevalu;
      // 昵称输入框正则验证
      let reg = /^(?!root$|admin$)[\u4e00-\u9fa5\w]{2,8}$/;
      if (!reg.test(res)) {
        this.$message({
          message:
            "最少输入2个字符，最多输入8个字符，可以包含汉字、字母、下划线,不包含特殊字符",
          type: "error",
        });
      } else {
        Set_Name(res).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.GetPersonalInfo();
            this.usershow = false;
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
        //头像修改
        Set_avatar(this.isupdataimg).then((res) => {
          if (res.data.code == 200) {
            PersonalInfo().then((res) => {
              this.User(res.data.data);
              this.$message({
                message: "修改成功",
                type: "success",
              });
              this.GetPersonalInfo();
            });
            this.usershow = false;
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
      }
    },
    uploadbease() {
      // 头像点击上传
      this.show = !this.show;
      let filedom = document.getElementById("upload");
      filedom.click();
    },
    handleFileUpload(e) {
      // window.URL.createObjectURL(e.target.files[0])
      const files = e.target.files; // 获取选择的文件列表
      const fileSize = files[0].size; // 获取第一个文件的大小，以字节为单位
      // 检查文件大小是否超过 512KB
      if (fileSize > 512 * 1024) {
        this.$message({
          message: "文件大小超过限制，最大允许上传 512KB 的图片。",
          type: "error",
        });
        return; // 停止执行上传操作
      } else {
        var reader = new FileReader();
        reader.onload = (e) => {
          let beas = e.target.result;
          base64(beas).then((res) => {
            if (res.data.code == 200) {
              this.isupdataimg = res.data.data.url;
            } else {
              this.$message({
                message: res.data.message,
                type: "error",
              });
            }
          });
        };
        reader.readAsDataURL(e.target.files[0]);
      }
    },
    // 选择头像改变
    imgchange(v, i) {
      this.changehead = i;
      this.isupdataimg = v.url;
    },
    //头像上传
    toggleShow() {
      this.usershow = !this.usershow;
      this.namevalu = this.Name;
      this.isupdataimg = this.avatar;
    },
    changetab(v) {
      this.actitletab = v;
      this.$router.push({
        path: "/Newuser",
        query: {
          TabIndex: this.actitletab,
        },
      });
    },
    iconclick(v) {
      switch (v) {
        case 1:
          this.$message.error("暂时不支持修改");
          // this.phonebindshow = true;
          // this.put1 = true;
          break;
        case 2:
          let info = this.user;
          if (info.true_name) {
            this.$message.error("已经实名，请勿重复填写");
          } else {
            this.namebindshow = true;
            this.put1 = true;
          }
          break;
        case 3:
          this.passwordshow = true;
          this.put1 = true;
          break;
      }
    },
    icontitle(v, i) {
      this.icontitlevalu = v;
      this.actitle = i;
    },
    leacveicon() {
      this.actitle = null;
    },
    getrecheargedata() {
      getrechargeinfoData().then((res) => {
        let data = res.data.data;
        if (res.data.code == 200) {
          this.Totalrecharge = data.total_recharge;
          this.monthlyrecharge = data.month_recharge;
          this.Totalretrieval = data.total_extract;
          this.monthlyretrieval = data.month_extract;
        } else {
          this.$message({
            message: res.data.data.message,
            type: "error",
          });
        }
      });
    },
    ...mapActions({
      MyInfo: "Refresh",
    }),
    getsteamyzm() {
      if (!myreg.test(this.phonevalue)) {
        //验证手机号格式是否正确
        this.$message({
          message: "手机号格式错误",
          type: "error",
        });
      } else {
        //4用来验证手机
        PostSendSms(this.phonevalue, 4).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.setsteamshow = false;
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
            this.setsteamshow = false;
          }
        });
        this.GetPersonalInfo();
      }
    },
    //手机绑定区
    //获取验证码
    getyzm() {
      if (!myreg.test(this.phonevalue)) {
        //验证手机号格式是否正确
        this.$message({
          message: "手机号格式错误",
          type: "error",
        });
      } else {
        //1:注册 2：
        PostSendSms(this.phonevalue, 3).then((res) => {});
      }
    },
    // 确认绑定
    doenter() {
      this.put1 = false;
      this.put2 = true;
    },
    // 判断是否满18岁
    checkID18(IDCard) {
      //获取用户身份证号码
      var userCard = IDCard;
      //如果身份证号码为undefind则返回空
      if (!userCard) {
        return false;
      }
      //获取出生年月日
      var yearBirth = userCard.substring(6, 10);
      var monthBirth = userCard.substring(10, 12);
      var dayBirth = userCard.substring(12, 14);
      //获取当前年月日并计算年龄
      var myDate = new Date();
      var monthNow = myDate.getMonth() + 1;
      var dayNow = myDate.getDate();
      var age = myDate.getFullYear() - yearBirth;
      if (
        monthNow < monthBirth ||
        (monthNow == monthBirth && dayNow < dayBirth)
      ) {
        age--;
      }
      if (age >= 18) {
        return true;
      }
      return false;
    },
    //实名认证
    doentername() {
      if (!this.isAllowRealName) {
        this.$message.error("请勿频繁点击，10秒后再次尝试");
        return false;
      }
      this.isAllowRealName = false;
      setTimeout(() => {
        this.isAllowRealName = true;
      }, 10000);
      if (!ID.test(this.nameID)) {
        //验证身份证格式
        this.$message({
          message: "身份证格式错误",
          type: "error",
        });
      } else {
        const result = this.checkID18(this.nameID);
        if (!result) {
          this.$message.error("未满18岁");
          return false;
        }
        const params = {
          true_name: this.namevalue,
          card_id: this.nameID,
        };
        if (this.flag) {
          this.flag = false;
          setTimeout(() => {
            this.flag = true;
          }, 3000);
          checkNameAndCardId(params).then((res) => {
            if (res.data.code === 200) {
              this.put1 = false;
              this.put2 = true;
              this.MyInfo();
            } else {
              this.$message({
                message: res.data?.message,
                type: "error",
              });
            }
          });
        }
      }
    },
    //实名认证完毕

    //修改密码
    getpassyzm() {
      //获取修改密码验证码
      if (this.phonevalue) {
        PostSendSms(this.phonevalue, 3).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
      } else {
        this.$message({
          message: "请输入手机号",
          type: "error",
        });
      }
    },
    dopass() {
      if (this.passyzm == "") {
        this.$message({
          message: "请输入验证码",
          type: "error",
        });
      } else {
        if (!reg.test(this.newpass, this.enterpass)) {
          this.$message({
            message: "密码格式错误,最少输入6位最多输入12位密码,包含字母数字",
            type: "error",
          });
        } else {
          if (this.newpass != this.enterpass) {
            this.$message({
              message: "两次密码输入错误",
              type: "error",
            });
          } else {
            Reset_password(this.phonevalue, this.enterpass, this.passyzm).then(
              (res) => {
                if (res.data.code == 200) {
                  this.put1 = false;
                  this.put2 = true;
                  // this.loginOut();
                } else {
                  this.$message({
                    message: res.data.message,
                    type: "error",
                  });
                }
              }
            );
          }
        }
      }
    },

    backlogin() {
      this.GetIndividualsShow(false);
      this.passwordshow = false;
      this.Leave();
      this.$router.push("/Home");
    },
    //退出
    loginOut() {
      this.GetIndividualsShow(false);
      this.Leave();
      this.$router.push("/Home");
    },

    ...mapMutations(["GetIndividualsShow", "Leave", "User"]),
    //获取个人信息
    GetPersonalInfo(user) {
      if (user != undefined) {
        localStorage.setItem("boxUserInfo", JSON.stringify(user));
        this.message = user;
        this.steam_id = user?.steam_id;
        this.Name = user?.name;
        this.Email = user?.email;
        this.mobile = user?.mobile;
        this.created_at = user?.created_at;
        this.updated_at = user?.updated_at;
        this.steam_url = user?.steam_url;
        this.inviter_id = user?.inviter_id;
        this.inviter = user?.inviter;
        this.invite_code2 = user?.inviter?.invite_code;
        this.avatar = user?.avatar;
        this.user_id = user?.id + "";
      }
    },

    //绑定邀请人
    setinvite_code() {
      if (this.invite_code_bangding == "") {
        this.$message({
          message: "请输入邀请码",
          type: "error",
        });
      } else {
        set_inviter(this.invite_code_bangding).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            window.location.reload();
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
            // window.location.reload()
          }
          this.GetPersonalInfo();
        });
      }
    },

    //设置Steam连接
    GetSteamUrl() {
      if (this.steam_url == "") {
        this.$message({
          message: "请输入steam链接",
          type: "error",
        });
        return;
      }
      if (this.phonevalue == "" || this.steamphoneyzm == "") {
        this.$message({
          message: "请输入手机号和验证码",
          type: "error",
        });
        return;
      }
      let params = {
        steam_url: this.steam_url,
        verify: this.steamphoneyzm,
        mobile: this.phonevalue,
      };
      SteamUrl(params).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.message,
            type: "error",
          });
        }
        this.GetPersonalInfo();
      });
    },
  },
  computed: {
    ...mapState(["user"]),
    restPhone() {
      if (this.mobile?.length > 0) {
        return this.mobile.replace(
          this.mobile.substring(3, this.mobile?.length),
          "********"
        );
      }
      return "";
    },
  },
  watch: {
    user(v) {
      this.GetPersonalInfo(v);
    },
    $route: {
      handler(newVal) {
        this.actitletab = newVal.query.TabIndex;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#newuserbox {
  width: 14.85rem;
  margin: 0.1rem auto;
  height: 11.48rem;
  background: url("../../assets/images/NewUser/pc-nuserbg1.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  .lbox {
    width: 3.27rem;
    height: 100%;
    border-right: 0.02rem solid #090b60;
    position: relative;
    .logouticon {
      position: absolute;
      top: 0.2rem;
      right: -0.1rem;
      width: 0.9rem;
      text-align: center;
      img {
        width: 0.3rem;
      }
      p {
        font-size: 0.12rem;
      }
    }
    .lcenter {
      width: 2.86rem;
      margin: 0 auto;
      overflow: hidden;
      .logintime {
        margin-top: 0.2rem;
        font-size: 0.12rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #9e9e9e;
      }
      .userinfo {
        width: 100%;
        height: 3.9rem;
        .userhead {
          width: 100%;
          height: 2.12rem;
          display: flex;
          justify-content: center;
          align-items: center;
          .headimg {
            width: 1.25rem;
            height: 1.25rem;
            border-radius: 50%;
            background: url("../../assets/images/about/user/user-pic.png")
              no-repeat;
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 0.9rem;
              height: 0.9rem;
              border-radius: 50%;
            }
          }
        }
        .userphone {
          text-align: center;
          font-size: 0.16rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          .phonetext {
            width: 1rem;
            white-space: nowrap;
          }
          img {
            width: 0.22rem;
            height: 0.22rem;
            margin-left: 0.12rem;
          }
        }
        .username1 {
          text-align: center;
          font-size: 0.18rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          margin-bottom: 0.12rem;
        }
        .username {
          text-align: center;
          font-size: 0.16rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          .phonetext {
            width: 1rem;
            white-space: nowrap;
          }
          img {
            width: 0.22rem;
            height: 0.22rem;
            margin-left: 0.12rem;
          }
        }
        .changeitem {
          display: flex;
          width: 1.8rem;
          margin: 0.12rem auto;
          justify-content: space-between;
          align-items: center;
          .changeicon {
            width: 0.6rem;
            height: 0.4rem;
            cursor: pointer;
            text-align: center;
            img {
              width: 0.4rem;
            }
            .changetitle {
              text-align: center;
              margin-top: 0.1rem;
              font-size: 0.14rem;
            }
          }
        }
      }
      .infolist {
        width: 100%;
        height: 0.34rem;
        background: url("../../assets/images/NewUser/pc-newuser-infolist.png")
          no-repeat;
        background-size: 100% 100%;
        margin-bottom: 0.08rem;
        line-height: 0.34rem;
        font-size: 0.16rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #c5bbed;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0.2rem;
      }
      .putbox {
        width: 100%;
        height: 0.97rem;
        background: #101023;
        border-radius: 0.02rem;
        margin-top: 0.18rem;
        padding: 0.08rem 0.12rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        border: 0.01rem solid #2f2f5e;
        .puttitle {
          margin-bottom: 0.08rem;
        }
        .put {
          text-decoration: none;
          border: 0.01rem solid #8686db;
          background: #2f2f5e;
          width: 100%;
          height: 0.22rem;
          color: #8080b5;
          text-indent: 0.12rem;
        }
        .entput {
          height: 0.2rem;
          line-height: 0.2rem;
          text-align: center;
          font-size: 0.1rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #8080b5;
        }
        .btnbox {
          display: flex;
          justify-content: flex-end;
          margin-top: 0.08rem;
          .putbtn {
            width: 0.6rem;
            height: 0.23rem;
            background: linear-gradient(135deg, #242448 0%, #42426d 100%);
            text-align: center;
            line-height: 0.23rem;
            border: 0.01rem solid #8686db;
            border-radius: 0.02rem;
            font-size: 0.12rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }

      .helpebox {
        width: 100%;
        height: 0.2rem;
        display: flex;
        margin-top: 0.08rem;
        justify-content: space-between;
        img {
          width: 0.14rem;
          height: 0.14rem;
          margin-right: 0.12rem;
        }
        .msg {
          width: 65%;
          font-size: 0.12rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #989898;
        }
        .clickhelpe {
          padding: 0.02rem 0.12rem;
          background: linear-gradient(135deg, #242448 0%, #42426d 100%);
          font-size: 0.1rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          text-decoration: none;
          line-height: 0.15rem;
          border: 0.01rem solid #8686db;
          border-radius: 0.02rem;
        }
      }

      .userheadbox {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .changeheadbox {
          width: 7.96rem;
          height: 3.22rem;
          background: rgba(8, 8, 30, 0.8);
          border-radius: 0.04rem;
          border: 0.02rem solid #5d5d9b;
          .h_title {
            width: 100%;
            height: 0.52rem;
            text-align: center;
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 0.2rem;
            color: #ffffff;
            line-height: 0.52rem;
          }
          .line {
            width: 7.94rem;
            margin: 0 auto;
            height: 0;
            border: 0.01rem solid;
            border-image: linear-gradient(
                90deg,
                rgba(93, 93, 155, 0),
                rgba(93, 93, 155, 1),
                rgba(93, 93, 155, 0)
              )
              1 1;
          }
          .a_content {
            width: 7.39rem;
            margin: 0.17rem auto;
            display: flex;
            justify-content: space-between;
            .activeimgbox {
              width: 5.02rem;
              height: 2.13rem;
              .imgtitle {
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 0.12rem;
                color: #7f7fb5;
              }
              .imgbox {
                width: 5rem;
                height: 1.9rem;
                border: 0.01rem solid #32325d;
                margin-top: 0.06rem;

                .imgcenter {
                  width: 4.65rem;
                  margin: 0.14rem auto;
                  display: flex;
                  flex-wrap: wrap;
                }
                .addimg {
                  width: 0.64rem;
                  height: 0.64rem;
                  margin-right: 0.13rem;
                  margin-top: 0.13rem;
                  img {
                    width: 100%;
                  }
                }
                .l_imgbox {
                  width: 0.64rem;
                  height: 0.64rem;
                  margin-right: 0.13rem;
                  margin-top: 0.13rem;
                  img {
                    width: 100%;
                    z-index: 1;
                  }
                }
                .acimgbox {
                  width: 0.64rem;
                  height: 0.64rem;
                  background: url("../../assets/images/NewUser/pc-newuser-headactive.png")
                    no-repeat;
                  background-size: 100% 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  img {
                    width: 0.6rem;
                  }
                }
              }
            }
            .nikenamebox {
              width: 2.12rem;
              height: 1.96rem;
              margin-top: 0.14rem;
              .upimgbox {
                width: 0.84rem;
                height: 0.84rem;
                margin: 0 auto;
                border-radius: 50%;
                border: 0.004rem solid #5d5d9b;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                  width: 0.7rem;
                  height: 0.7rem;
                  border-radius: 50%;
                }
              }
              .putnamebox {
                width: 100%;
                display: flex;
                justify-content: center;
                .putname {
                  width: 1.5rem;
                  height: 0.23rem;
                  margin: 0.17rem auto;
                  border: 0.01rem solid #32325d;
                  outline: none;
                  text-align: center;
                  background: #08081e;
                  font-family: PingFang SC, PingFang SC;
                  font-weight: 400;
                  font-size: 0.12rem;
                  color: #bfbfed;
                }
              }
              .enterbtnbox {
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-top: 0.3rem;
                .namebtn {
                  width: 0.98rem;
                  height: 0.23rem;
                  text-align: center;
                  line-height: 0.23rem;
                  border-radius: 0.02rem;
                  font-family: PingFang SC, PingFang SC;
                  font-weight: 400;
                  font-size: 0.12rem;
                  color: #ffffff;
                }
                .nb1 {
                  border: 0.01rem solid #303064;
                  background: linear-gradient(
                    135deg,
                    #282845 100%,
                    #12122f 100%
                  );
                }
                .nb2 {
                  border: 0.01rem solid #5a5a9a;
                  background: linear-gradient(
                    135deg,
                    #282845 100%,
                    #42426d 100%
                  );
                }
              }
            }
          }
        }
      }
    }

    .namebindbox {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 7.96rem;
      height: 2.6rem;
      background: #09091f;
      border: 0.02rem solid #5d5d9b;
      border-radius: 0.04rem;
      .titlebox {
        width: 100%;
        height: 0.51rem;
        line-height: 0.51rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 0.2rem;
        color: #ffffff;
        text-align: center;
        position: relative;
        .closeicon {
          width: 0.16rem;
          height: 0.16rem;
          position: absolute;
          right: 0.15rem;
          top: 0.15rem;
        }
      }
      .line {
        width: 100%;
        height: 0;
        border: 0.01rem solid;
        border-image: linear-gradient(
            90deg,
            rgba(93, 93, 155, 0),
            rgba(93, 93, 155, 1),
            rgba(93, 93, 155, 0)
          )
          1 1;
      }
      .okbox {
        width: 100%;
        height: 1.35rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .iconinfo {
          text-align: center;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.16rem;
          color: #bfbfed;
          img {
            width: 0.46rem;
          }
        }
      }
      .namecenter {
        width: 4.5rem;
        margin: 0.3rem auto;
      }
      .changeputbox {
        width: 100%;
        margin: 0.12rem auto;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        span {
          width: 0.9rem;
          height: 0.22rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.16rem;
          color: #bfbfed;
          margin-right: 0.1rem;
        }
        .putcalss {
          width: 3.46rem;
          height: 0.3rem;
          text-decoration: none;
          border: 0.01rem solid #5d5d9b;
          font-family: PingFang SC, PingFang SC;
          font-weight: 300;
          font-size: 0.1rem;
          color: rgba(191, 191, 237, 0.5);
          text-indent: 0.12rem;
        }
      }
      .yzmputbox {
        margin: 0 auto;
        display: flex;
        align-items: center;
        .putcalss {
          width: 2.58rem;
        }
        .yzmbtn {
          width: 0.82rem;
          height: 0.3rem;
          text-align: center;
          line-height: 0.3rem;
          background: linear-gradient(135deg, #242448 0%, #42426d 100%);
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.12rem;
          color: #ffffff;
          margin-left: 0.06rem;
        }
      }
      .updatabtnbox {
        width: 100%;
        display: flex;
        justify-content: center;
        .updatabtn {
          width: 0.98rem;
          height: 0.28rem;
          text-align: center;
          line-height: 0.28rem;
          background: linear-gradient(135deg, #242448 0%, #42426d 100%);
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.12rem;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
    .passwordbox {
      height: 3.1rem;
    }
  }
  .rbox {
    width: 11.58rem;
    .rcenter {
      width: 10.44rem;
      margin: 0.42rem auto;
      .titletab {
        width: 100%;
        height: 0.47rem;
        border-bottom: 0.02rem solid #2f2f5e;
        display: flex;
        .tabitem {
          width: 1.48rem;
          height: 0.47rem;
          text-align: center;
          line-height: 0.47rem;
        }
        .t1 {
          background: url("../../assets/images/NewUser/pc-newuser-titletab1.png")
            no-repeat;
          background-size: 100% 100%;
          color: #dedede;
        }
        .t1-1 {
          background: url("../../assets/images/NewUser/pc-newuser-titletab1-1.png")
            no-repeat;
          color: #fff;
        }
        .t2 {
          background: url("../../assets/images/NewUser/pc-newuser-titletab2.png")
            no-repeat;
          background-size: 100% 100%;
          transform: translateX(-15%);
          color: #dedede;
        }
        .t3 {
          background: url("../../assets/images/NewUser/pc-newuser-titletab2.png")
            no-repeat;
          background-size: 100% 100%;
          transform: translateX(-30%);
          color: #dedede;
        }
        .t2-2 {
          background: url("../../assets/images/NewUser/pc-newuser-titletab2-2.png")
            no-repeat;
          color: #fff;
        }
        .t4 {
          background: url("../../assets/images/NewUser/pc-newuser-titletab3.png")
            no-repeat;
          background-size: 100% 100%;
          transform: translateX(-45%);
          color: #dedede;
        }
        .t3-3 {
          background: url("../../assets/images/NewUser/pc-newuser-titletab3-3.png")
            no-repeat;
          color: #fff;
        }
      }
    }
  }
}
</style>
