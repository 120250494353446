export const tableRecordList3 = [
    {
        title: "回收时间",
        width: "25%",
        index: "updated_at",
        center: "center",
    },
    {
        title: "名称",
        width: "35%",
        index: "name",
        center: "center",
        render: true
    },
    {
        title: "状态",
        width: "25%",
        index: "status_alias",
        center: "center",
    },
    {
        title: "回购价",
        width: "10%",
        index: "bean",
        center: "center",
    },
];
export const tableRecordList2 = [
    {
        title: "提货发起时间",
        width: "25%",
        index: "updated_at",
        center: "center",
    },
    {
        title: "名称",
        width: "35%",
        index: "name",
        center: "center",
        render: true
    },
    {
        title: "状态",
        width: "25%",
        index: "status_alias",
        center: "center",

    },
    // {
    //     title: "回购价",
    //     width: "10%",
    //     index: "bean",
    //     center: "center",
    // },
];

export const tableRecordList4 = [
    {
        title: "交易时间",
        width: "25%",
        index: "created_at",
        center: "center",
    },
    {
        title: "交易类型",
        width: "25%",
        index: "change_type_alias",
        center: "center",
    },
    // {
    //   title: "订单号",
    //   width: "30%",
    //   index: "orderIo",
    //   center: "center",
    // },
    {
        title: "变动金额",
        width: "25%",
        index: "bean",
        center: "center",
    },
    {
        title: "变动余额",
        width: "25%",
        index: "final_bean",
        center: "center",
    }
];
export const tableRecordList10 = [
    {
        title: "交易时间",
        width: "25%",
        index: "created_at",
        center: "center",
    },
    {
        title: "交易类型",
        width: "25%",
        index: "change_type_alias",
        center: "center",
    },
    // {
    //   title: "订单号",
    //   width: "30%",
    //   index: "orderIo",
    //   center: "center",
    // },
    {
        title: "积分变动",
        width: "25%",
        index: "bean",
        center: "center",
    },
    {
        title: "积分余额",
        width: "25%",
        index: "final_bean",
        center: "center",
    }
];
// 近期奖励
export const tableRecordList5 = [
    {
        title: "时间",
        width: "30%",
        index: "time",
        center: "center",
    },
    {
        title: "下级名称",
        width: "30%",
        index: "name",
        center: "center",
    },
    {
        title: "充值金额",
        width: "20%",
        index: "charge_bean",
        center: "center",
    },
    {
        title: "推广奖励",
        width: "20%",
        index: "price",
        center: "center",
        render: true
    }
];
// 下级详情
export const tableRecordList6 = [
    {
        title: "用户ID",
        width: "30%",
        index: "name",
        center: "center",
    },
    {
        title: "充值",
        width: "30%",
        index: "total_recharge",
        center: "center",
    },
    {
        title: "消耗",
        width: "30%",
        index: "total_record",
        center: "center",
    }
];
// 主播领取
export const tableRecordList7 = [
    {
        title: "结算日",
        width: "22%",
        index: "date",
        center: "center",
    },
    {
        title: "充值次数",
        width: "15%",
        index: "change_num",
        center: "center",
    },
    {
        title: "充值金额",
        width: "15%",
        index: "change_money",
        center: "center",
        render: true
    },
    {
        title: "佣金比例",
        width: "15%",
        index: "commission_rate",
        center: "center",
        render: true
    },
    {
        title: "我的佣金",
        width: "15%",
        index: "commission_money",
        center: "center",
        render: true
    },
    {
        title: "佣金每周二结算",
        width: "15%",
        index: "status",
        center: "center",
        render: true
    },
];
// 弹药
export const tableRecordList8 = [
    {
        title: "交易时间",
        width: "20%",
        index: "created_at",
        center: "center",
    },
    {
        title: "交易类型",
        width: "15%",
        index: "type",
        center: "center",
        render: true
    },
    {
        title: "开箱投入",
        width: "23%",
        index: "names",
        center: "center",
        render: true
    },
    {
        title: "开箱获得",
        width: "23%",
        index: "box_record_names",
        center: "center",
        render: true
    },
    {
        title: "赠送物品",
        width: "23%",
        index: "award_name",
        center: "center",
        render: true
    }
];
// 熔炼
export const tableRecordList9 = [
    {
        title: "交易时间",
        width: "25%",
        index: "created_at",
        center: "center",
    },
    {
        title: "交易类型",
        width: "15%",
        index: "type",
        center: "center",
        render: true
    },
    {
        title: "熔炼投入",
        width: "25%",
        index: "names",
        center: "center",
        render: true
    },
    {
        title: "熔炼获得",
        width: "30%",
        index: "award_name",
        center: "center",
        render: true
    }
];
export const tableData = {
    total: 15,
    pageSize: 15,
    current_page: 1,
    list: [
        {}
    ],
};
export const acceptData = {
    total: 0,
    pager: 0,
    current_page: 1,
    list: [
        {}
    ],
};

export const newlistData = {
    total: 0,
    pageSize: 0,
    current_page: 1,
    list: [
        {}
    ],
};


// import img1 from "@/assets/images/about/user/1.jpg";
// import img2 from "@/assets/images/about/user/2.jpg";
// import img3 from "@/assets/images/about/user/3.jpg";
// import img4 from "@/assets/images/about/user/4.jpg";
// import img5 from "@/assets/images/about/user/5.jpg";
// import img6 from "@/assets/images/about/user/6.jpg";
// import img7 from "@/assets/images/about/user/7.jpg";
// import img8 from "@/assets/images/about/user/8.jpg";
// import img9 from "@/assets/images/about/user/9.jpg";
// import img10 from "@/assets/images/about/user/10.jpg";
// import img11 from "@/assets/images/about/user/11.jpg";
export const imgurlvalue = [
    {
        url: 'https://api.tmskins.com/uploads/images/202404/08/217257_l1rrFhGpyHN7T7pT.png'
    },
    {
        url: 'https://api.tmskins.com/uploads/images/202404/08/217257_zZi77WKx26zBa6nh.png'
    },
    {
        url: "https://api.tmskins.com/uploads/images/202404/08/217257_dxkmspDCscvhKVO9.png"
    },
    {
        url: "https://api.tmskins.com/uploads/images/202404/08/217257_Z1pj1YUFpusucWhu.png"
    },
    {
        url: "https://api.tmskins.com/uploads/images/202404/08/217257_PgkULvMz4bydhSpw.png"
    },
    {
        url: "https://api.tmskins.com/uploads/images/202404/08/217257_pJNNDAPlQxji7A9g.png"
    },
    {
        url: "https://api.tmskins.com/uploads/images/202404/08/217257_liYHpsTESxR8sHXW.png"
    },
    {
        url: "https://api.tmskins.com/uploads/images/202404/08/217257_71v5i0KUTDx2yYbL.png"
    },
    {
        url: "https://api.tmskins.com/uploads/images/202404/08/217257_j3f3fuqmXfjWdT6y.png"
    },

    {
        url: "https://api.tmskins.com/uploads/images/202404/08/217257_45gYqmq2Y97e0NcM.png"
    },

    {
        url: "https://api.tmskins.com/uploads/images/202404/08/217257_g8eqtD5J5D9Zr3a4.png"
    }
];